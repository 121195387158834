var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    {
      staticClass: "c-card overflow-hidden mb-0 h-100 pageViewMarketplace",
      attrs: { color: "second-card-bg" },
    },
    [
      _c(
        "CCardBody",
        { staticClass: "c-card-body d-flex flex-column h-100" },
        [
          _c("div", { staticClass: "pageViewMarketplace__header" }, [
            _c("span", { staticClass: "pageViewMarketplace__header-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("marketplace.title")) + " "),
            ]),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c(
                  "CButton",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.toolTipContent,
                        expression: "toolTipContent",
                      },
                    ],
                    staticClass: "pageViewMarketplace__add-button",
                    attrs: { color: "primary" },
                    on: { click: _vm.toggleShowNotificationModal },
                  },
                  [
                    _c("CIcon", { attrs: { name: "cil-plus" } }),
                    !_vm.isMobile
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.notifications.addBtn")) +
                              " "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm.isMobile
                  ? _c(
                      "div",
                      { staticClass: "pageViewMarketplace__tokens-wrapper" },
                      [
                        _vm.loadingTokens || _vm.loadingNotify
                          ? _c("CSpinner", {
                              staticClass: "mx-2",
                              attrs: { color: "primary", size: "sm" },
                            })
                          : [
                              this.user.getAccess(2)
                                ? _c(
                                    "CButton",
                                    {
                                      staticClass: "shadow-none",
                                      attrs: {
                                        color: _vm.isPaused
                                          ? "warning"
                                          : "primary",
                                        disabled:
                                          _vm.loadingTokens ||
                                          _vm.loadingNotify,
                                      },
                                      on: { click: _vm.togglePause },
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: {
                                          name: _vm.isPaused
                                            ? "cil-media-play"
                                            : "cil-media-pause",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "pageViewMarketplace__controls" }, [
            _c(
              "div",
              {
                staticClass:
                  "pageViewMarketplace__filters flex-wrap align-items-stretch",
              },
              [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c(
                    "span",
                    { staticClass: "pageViewMarketplace__filters-label" },
                    [_vm._v(" " + _vm._s(_vm.$t("marketplace.sort")) + ": ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "pageViewMarketplace__filters-wrapper" },
                    [
                      _c(
                        "span",
                        {
                          class: [
                            "pageViewMarketplace__filters-item",
                            { active: _vm.orderBy === "subscriptions_count" },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.sortList("subscriptions_count")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isMobile
                                  ? _vm.$t("marketplace.subscribersNumberAbbr")
                                  : _vm.$t("marketplace.subscribersNumber")
                              ) +
                              " "
                          ),
                          _c("CIcon", {
                            class: {
                              reverse:
                                _vm.orderBy === "subscriptions_count" &&
                                _vm.sortDir === "DESC",
                            },
                            attrs: { name: "arrow-trends" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          class: [
                            "pageViewMarketplace__filters-item",
                            { active: _vm.orderBy === "published_date" },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.sortList("published_date")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("marketplace.publicationDate")) +
                              " "
                          ),
                          _c("CIcon", {
                            class: {
                              reverse:
                                _vm.orderBy === "published_date" &&
                                _vm.sortDir === "DESC",
                            },
                            attrs: { name: "arrow-trends" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between flex-wrap pageViewMarketplace__flex-gaps",
                  },
                  [
                    _c(
                      "CButtonGroup",
                      { staticClass: "d-flex" },
                      _vm._l(_vm.marketplace, function (item, i) {
                        return _c(
                          "CButton",
                          {
                            key: i,
                            class:
                              _vm.activeMarketplace === item.value
                                ? "btn shadow-none button-custom text-white _active"
                                : "btn shadow-none button-custom text-white",
                            attrs: {
                              disabled: _vm.loadingNotify,
                              color: "scroll-handler",
                              size: "sm",
                              variant:
                                _vm.activeMarketplace === item.value
                                  ? ""
                                  : "outline",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.sortByMarketplace(item.value)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.labelI18n
                                    ? _vm.$t(item.labelI18n)
                                    : item.label
                                ) +
                                " "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                    _vm.isMobile
                      ? _c(
                          "div",
                          {
                            staticClass: "pageViewMarketplace__tokens-wrapper",
                          },
                          [
                            _vm.loadingTokens
                              ? _c("CSpinner", {
                                  staticClass: "mx-2",
                                  attrs: { color: "primary", size: "sm" },
                                })
                              : _c("TokensBlock", {
                                  attrs: { tokens: _vm.tokens },
                                }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            !_vm.isMobile
              ? _c(
                  "div",
                  { staticClass: "pageViewMarketplace__tokens-wrapper" },
                  [
                    _vm.loadingTokens
                      ? _c("CSpinner", {
                          staticClass: "mx-2",
                          attrs: { color: "primary", size: "sm" },
                        })
                      : [
                          this.user.getAccess(2) && _vm.subscribedList.length
                            ? _c(
                                "CButton",
                                {
                                  staticClass: "shadow-none mr-2",
                                  attrs: {
                                    color: _vm.isPaused ? "warning" : "primary",
                                    disabled: _vm.loadingTokens,
                                  },
                                  on: { click: _vm.togglePause },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: {
                                      name: _vm.isPaused
                                        ? "cil-media-play"
                                        : "cil-media-pause",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("TokensBlock", { attrs: { tokens: _vm.tokens } }),
                        ],
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _vm.showInfoModal
            ? _c("NotificationInfoModal", {
                attrs: {
                  show: _vm.showInfoModal,
                  currentNotification: _vm.currentNotification,
                  backTitle: _vm.documentTitle(),
                },
                on: {
                  update: _vm.getData,
                  close: _vm.closeInfoModal,
                  delete: _vm.showDeleteNotificationModal,
                },
              })
            : _vm._e(),
          _vm.showDeleteModal
            ? _c("DeleteGroupNotification", {
                attrs: {
                  show: _vm.showDeleteModal,
                  currentNotification: _vm.currentNotification,
                },
                on: {
                  close: function ($event) {
                    _vm.showDeleteModal = false
                  },
                  deleteNotification: _vm.deleteNotification,
                },
              })
            : _vm._e(),
          _vm.showModal
            ? _c("notification-modal", {
                key: _vm.activeMarketplace,
                attrs: {
                  notification: _vm.notification,
                  showModal: _vm.showModal,
                },
                on: {
                  close: _vm.closeEvent,
                  getNotifications: _vm.getNotifications,
                  "update:isFuturesForRerender": _vm.isFuturesForRerenderUpdate,
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "pageViewMarketplace__list-wrapper" },
            [
              _vm.loadingNotify || _vm.loadingTokens
                ? _c("CSpinner", {
                    staticClass: "mx-2",
                    attrs: { color: "primary", size: "sm" },
                  })
                : _vm._e(),
              !(_vm.loadingNotify || _vm.loadingTokens) &&
              _vm.groupNotifications.length
                ? _vm._l(_vm.groupNotifications, function (item) {
                    return _c("NotificationCardItem", {
                      key: item.id,
                      attrs: {
                        notificationData: item,
                        isSubscribed: item % 2 == 0,
                        tokens: _vm.tokens,
                      },
                      on: {
                        updateList: _vm.getData,
                        showInfoModal: function ($event) {
                          return _vm.notificationCardClick(item)
                        },
                        delete: _vm.showDeleteNotificationModal,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.notificationCardClick(item)
                        },
                      },
                    })
                  })
                : _vm._e(),
              !(_vm.loadingNotify || _vm.loadingTokens) &&
              !_vm.groupNotifications.length
                ? _c("p", { staticClass: "m-0" }, [
                    _vm._v(_vm._s(_vm.$t("lk.notifications.emptyActiveTable"))),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }