<template>
  <CCard
    class="c-card overflow-hidden mb-0 h-100 pageViewMarketplace"
    color="second-card-bg"
  >
    <CCardBody class="c-card-body d-flex flex-column h-100">
      <div class="pageViewMarketplace__header">
        <span class="pageViewMarketplace__header-title">
          {{ $t('marketplace.title') }}
        </span>
        <div class="d-flex justify-content-between">
          <CButton
            v-tooltip="toolTipContent"
            class="pageViewMarketplace__add-button"
            color="primary"
            @click="toggleShowNotificationModal"
          >
            <CIcon name="cil-plus" />
            <template v-if="!isMobile">
              {{ $t('lk.notifications.addBtn') }}
            </template>
          </CButton>
          <div
            v-if="isMobile"
            class="pageViewMarketplace__tokens-wrapper"
          >
            <CSpinner
              v-if="loadingTokens || loadingNotify"
              color="primary"
              size="sm"
              class="mx-2"
            />
            <template v-else>
              <CButton
                v-if="this.user.getAccess(2)"
                :color="isPaused ? 'warning' : 'primary'"
                :disabled="loadingTokens || loadingNotify"
                class="shadow-none"
                @click="togglePause"
              >
                <CIcon
                  :name="isPaused ? 'cil-media-play' : 'cil-media-pause'"
                />
              </CButton>
            </template>
          </div>
        </div>
      </div>

      <div class="pageViewMarketplace__controls">
        <div class="pageViewMarketplace__filters flex-wrap align-items-stretch">
          <div class="d-flex align-items-center">
            <span class="pageViewMarketplace__filters-label">
              {{ $t('marketplace.sort') }}:
            </span>
            <div class="pageViewMarketplace__filters-wrapper">
              <span
                @click="sortList('subscriptions_count')"
                :class="[
                  'pageViewMarketplace__filters-item',
                  {active: orderBy === 'subscriptions_count'},
                ]"
              >
                {{
                  isMobile
                    ? $t('marketplace.subscribersNumberAbbr')
                    : $t('marketplace.subscribersNumber')
                }}
                <CIcon
                  :class="{
                    reverse:
                      orderBy === 'subscriptions_count' && sortDir === 'DESC',
                  }"
                  name="arrow-trends"
                />
              </span>
              <span
                @click="sortList('published_date')"
                :class="[
                  'pageViewMarketplace__filters-item',
                  {active: orderBy === 'published_date'},
                ]"
              >
                {{ $t('marketplace.publicationDate') }}
                <CIcon
                  :class="{
                    reverse: orderBy === 'published_date' && sortDir === 'DESC',
                  }"
                  name="arrow-trends"
                />
              </span>
            </div>
          </div>

          <div class="d-flex justify-content-between flex-wrap pageViewMarketplace__flex-gaps">
            <!-- marketplace -->
            <CButtonGroup class="d-flex">
              <CButton
                v-for="(item, i) in marketplace"
                :key="i"
                :disabled="loadingNotify"
                color="scroll-handler"
                :size="'sm'"
                :class="activeMarketplace === item.value ? 'btn shadow-none button-custom text-white _active' : 'btn shadow-none button-custom text-white' "
                :variant="activeMarketplace === item.value ? '' : 'outline'"
                @click="sortByMarketplace(item.value)"
              >
                {{ item.labelI18n ? $t(item.labelI18n) : item.label }}
              </CButton>
            </CButtonGroup>

            <div
              v-if="isMobile"
              class="pageViewMarketplace__tokens-wrapper"
            >
              <CSpinner
                v-if="loadingTokens"
                color="primary"
                size="sm"
                class="mx-2"
              />
              <TokensBlock
                v-else
                :tokens="tokens"
              />
            </div>
          </div>
        </div>

        <div
          v-if="!isMobile"
          class="pageViewMarketplace__tokens-wrapper"
        >
          <CSpinner
            v-if="loadingTokens"
            color="primary"
            size="sm"
            class="mx-2"
          />
          <template v-else>
            <CButton
              v-if="this.user.getAccess(2) && subscribedList.length"
              :color="isPaused ? 'warning' : 'primary'"
              :disabled="loadingTokens"
              class="shadow-none mr-2"
              @click="togglePause"
            >
              <CIcon :name="isPaused ? 'cil-media-play' : 'cil-media-pause'" />
            </CButton>
            <TokensBlock :tokens="tokens" />
          </template>
        </div>
      </div>

      <NotificationInfoModal
        v-if="showInfoModal"
        :show="showInfoModal"
        :currentNotification="currentNotification"
        :backTitle="documentTitle()"
        @update="getData"
        @close="closeInfoModal"
        @delete="showDeleteNotificationModal"
      />
      <DeleteGroupNotification
        :show="showDeleteModal"
        :currentNotification="currentNotification"
        v-if="showDeleteModal"
        @close="showDeleteModal = false"
        @deleteNotification="deleteNotification"
      />
      <notification-modal
        :key="activeMarketplace"
        v-if="showModal"
        :notification="notification"
        :showModal="showModal"
        @close="closeEvent"
        @getNotifications="getNotifications"
        @update:isFuturesForRerender="isFuturesForRerenderUpdate"
      />
      <div class="pageViewMarketplace__list-wrapper">
        <CSpinner
          v-if="loadingNotify || loadingTokens"
          color="primary"
          size="sm"
          class="mx-2"
        />
        <template
          v-if="!(loadingNotify || loadingTokens) && groupNotifications.length"
        >
          <NotificationCardItem
            @updateList="getData"
            @showInfoModal="notificationCardClick(item)"
            v-for="item in groupNotifications"
            :notificationData="item"
            :isSubscribed="item % 2 == 0"
            :key="item.id"
            :tokens="tokens"
            @delete="showDeleteNotificationModal"
            @click.native="notificationCardClick(item)"
          />
        </template>
        <p
          v-if="!(loadingNotify || loadingTokens) && !groupNotifications.length"
          class="m-0"
          >{{ $t('lk.notifications.emptyActiveTable') }}</p
        >
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import DeleteGroupNotification from '@/components/notifications/DeleteGroupNotification.vue'
import TokensBlock from '@/components/marketplace/TokensBlock.vue'
import NotificationInfoModal from '@/components/marketplace/NotificationInfoModal.vue'
import NotificationCardItem from '@/components/marketplace/NotificationCardItem.vue'
import NotificationModal from '@/components/notifications/NotificationModal.vue'
import Condition from '@/assets/js/notifications/Condition.class'
import Notification from '@/assets/js/notifications/Notification.class'
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CIcon,
  CSpinner,
} from '@coreui/vue-pro'
import { cloneDeep } from 'lodash'

const initialNotify = new Notification({conditions: [new Condition()]})
export default {
  name: 'PageViewMarketplace',
  components: {
    NotificationCardItem,
    NotificationInfoModal,
    NotificationModal,
    TokensBlock,
    DeleteGroupNotification,
    CButton,
    CCard,
    CCardBody,
    CSpinner,
    CIcon,
    CButtonGroup,
  },
  data() {
    return {
      showModal: false,
      isFuturesForRerender: false,
      showDeleteModal: false,
      tokens: [],
      NotificationClass: Notification,
      groupNotifications: [],
      notification: initialNotify.copy(),
      orderBy: 'subscriptions_count',
      sortDir: 'DESC',
      showInfoModal: false,
      currentNotification: null,
      loadingTokens: false,
      loadingNotify: false,
      activeMarketplace: null,
      marketplace: {
        all: {
          id: 'all',
          label: 'All',
          labelI18n: 'lk.notifications.marketplace.types.all',
          value: null,
        },
        spot: {
          id: 'spot',
          label: 'Spot',
          labelI18n: 'lk.notifications.marketplace.types.spot',
          value: 0,
        },
        futures: {
          id: 'futures',
          label: 'Futures',
          labelI18n: 'lk.notifications.marketplace.types.futures',
          value: 1,
        },
      },
    }
  },
  mounted() {
    this.getNotifications()
    this.getTokens()
  },

  beforeUpdate() {
    if (this.$route?.query?.id) {
      const queryItem = this.groupNotifications.find((item) => {
        return item.id == this.$route?.query?.id
      })
      if (queryItem) {
        this.currentNotification = queryItem
        this.showInfoModal = true
      }
    }
  },
  computed: {
    ...mapGetters({
      isDesktop: 'mediaQuery/isDesktop',
      isTablet: 'mediaQuery/isTablet',
      isMobile: 'mediaQuery/isMobile',
    }),
    subscribedList() {
      return this.groupNotifications.filter(
        (item) => item.is_subscribed || item.byUser,
      )
    },
    isPaused() {
      return this.subscribedList.every((group) => group.isPaused)
    },
    user() {
      return this.$store.state.user.userData
    },
    toolTipContent() {
      if (!this.user.getAccess(1)) {
        return {
          content: `${this.$t('marketplace.actveTariffTooltip', {tariff: 'PRO+ALERTS'})}`,
        }
      }

      return {content: false}
    },
  },
  watch: {
    isFuturesForRerender() {

    },
    activeMarketplace() {
      this.isFuturesForRerender = false
    }
  },
  methods: {
    isFuturesForRerenderUpdate (value) {
      this.isFuturesForRerender = value
      this.closeEvent()
      this.$nextTick(() => {
        this.handleAddNotification()
      })
    },
    closeEvent() {
      this.showModal = false
      this.notification = initialNotify.copy()
    },
    openModal(notify, isFutures) {
      this.notification = cloneDeep(notify) || initialNotify.copy()
      if (isFutures !== undefined) {
        this.notification.is_futures_init = isFutures
        this.notification.is_futures = isFutures
      }
      this.showModal = true
    },
    handleAddNotification() {
      this.openModal(null, this.isFuturesForRerender)
    },
    deleteNotification() {
      axios
        .delete(`api/v1/signals/marketplace/${this.currentNotification.id}`)
        .then(({status}) => {
          if (status < 300) {
            this.$toastr.success(
              this.$t('lk.notifications.deleteNotificationNote'),
            )
            this.getData()
          }
        })
        .finally(() => {
          this.showDeleteModal = false
        })
    },
    showDeleteNotificationModal(val) {
      this.currentNotification = val
      this.showDeleteModal = true
    },
    togglePause() {
      this.loadingNotify = true
      this.loadingTokens = true
      axios
        .post('api/v1/signals/marketplace/enable-all-signals', {
          enable: !!this.isPaused,
        })
        // .then(({status, data}) => {
        //   if (status < 300) {
        //     this.groupNotifications = data.data.map(v => new Notification(v))
        //   }
        // })
        .finally(() => {
          this.getData()
          this.loadingNotify = false
          this.loadingTokens = false
        })
    },
    getData() {
      let params = {
        order_type: this.orderBy,
        order_direction: this.sortDir,
      }
      if (this.activeMarketplace !== null) {
        params.is_futures = this.activeMarketplace
      }
      this.getNotifications(params)
      this.getTokens()
    },
    ...mapActions('promo', ['toggleShowPromoModal']),
    closeInfoModal() {
      this.$router.replace({
        name: this.$route.name,
      })
      this.showInfoModal = false
    },
    toggleShowNotificationModal() {
      if (!this.user.getAccess(1)) {
        this.toggleShowPromoModal({
          show: true,
          content: {
            id: 'pro_alerts',
            sliderData: [{src: 'promo1'}, {src: 'promo2'}, {src: 'promo3'}],
          },
        })
      } else {
        this.showModal = true
      }
    },
    notificationCardClick(item) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          id: item.id,
          ref: this.user.referralCode,
        },
      })
      this.currentNotification = item
      this.showInfoModal = true
    },
    sortList(sortBy) {
      if (sortBy !== this.orderBy) {
        this.orderBy = sortBy
        this.sortDir = 'DESC'
      } else {
        this.sortDir === 'DESC'
          ? (this.sortDir = 'ASC')
          : (this.sortDir = 'DESC')
      }
      let params = {
        order_type: this.orderBy,
        order_direction: this.sortDir,
      }
      if (this.activeMarketplace !== null) {
        params.is_futures = this.activeMarketplace
      }
      this.getNotifications(params)
    },
    sortByMarketplace(value) {
      this.activeMarketplace = value
      let params = {
        order_type: this.orderBy,
        order_direction: this.sortDir,
      }
      if (this.activeMarketplace !== null) {
        params.is_futures = this.activeMarketplace
      }
      this.getNotifications(params)
    },
    getNotifications(params = {}) {
      this.loadingNotify = true
      const url = 'api/v1/signals/marketplace-list'
      axios
        .get(url, {params: params})
        .then(({status, data}) => {
          if (status < 300) {
            this.groupNotifications = data.data.map((v) => new Notification(v))
          }
        })
        .finally(() => {
          this.loadingNotify = false
        })
    },
    getTokens() {
      this.loadingTokens = true
      const url = 'api/v2/marketplace-tokens/user'
      axios
        .get(url)
        .then(({status, data}) => {
          if (status < 300) {
            this.tokens = data.data
          }
        })
        .finally(() => {
          this.loadingTokens = false
        })
    },
    documentTitle() {
      return document.title
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/includes/dark-schema';

.pageViewMarketplace {
  &__header,
  &__controls {
    margin-left: 2px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__add-button {
    width: 40px;
    height: 35px;
    margin-right: 5px;
    @include media-breakpoint-up(md) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      width: initial;
      height: initial;
      margin-right: 16px;
    }
  }

  &__header-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      font-size: 24px;
      line-height: 29px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 35px;
      line-height: 42px;
    }
  }
  &__controls {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: end;
    }
  }
  &__tokens-wrapper {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      margin-top: 16px;
    }
    @include media-breakpoint-up(xxl) {
      margin-top: 18px;
    }
  }
  &__flex-gaps {
    column-gap: 9px;
    row-gap: 10px;
    @include media-breakpoint-up(md) {
      column-gap: 32px;
    }
  }
  &__filters {
    order: 1;
    display: flex;
    flex-direction: column;
    margin-top: 17px;
    align-items: baseline;
    column-gap: 9px;
    row-gap: 10px;
    @include media-breakpoint-up(md) {
      column-gap: 32px;
    }
    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-top: 0;
      order: 0;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }
  }

  &__filters-label {
    opacity: 0.5;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  &__filters-wrapper {
    margin-left: 8px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 9px;
    row-gap: 10px;
    @include media-breakpoint-up(md) {
      column-gap: 32px;
    }
  }

  &__filters-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.3px;
    opacity: 0.5;
    transition: 0.3s;

    &.active {
      opacity: 1;
    }

    & svg {
      &.reverse {
        transform: rotate(180deg);
      }

      font-weight: 700;
      width: 10px !important;
      height: 7px !important;
      margin-left: 3px;
      transition: 0.3s;
    }
  }

  &__list-wrapper {
    padding-right: 8px;
    margin-top: 16px;
    display: grid;
    gap: 12px;
    overflow: auto;
    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 20px;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }
}

.button-custom {
  padding: 14px 20px;
  border: none;
  background: #e6e6e6;

  @include dark-schema {
    background: var(--third-card-bg)
  }

  @include media-breakpoint-up(md) {
    padding: 11.5px 20px;
    max-width: 150px;

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 150px;
  }

  &:before {
    box-shadow: none !important;
  }

  &._active {
    background: #d9d9d9;

    @include dark-schema {
      background: #484956;
    }
  }
}
</style>
